import React, { Component } from "react"

export default class Calender extends Component {
  loadScriptDynamically(src, bookalet, property) {
    let script = document.createElement("script")
    script.src = src
    script.setAttribute("data-bookalet", bookalet)
    script.setAttribute("data-property", property)
    document.querySelector(".cal").appendChild(script)
  }

  componentDidMount() {
    this.loadScriptDynamically(
      "https://widgets.bookalet.co.uk/publish.js",
      "b61d1394-11ba-4a54-9ee2-b7d6ec43b214",
      "12783"
    )
  }
  render() {
    return (
      <div>
        <section className="cal"></section>
      </div>
    )
  }
}
